import MissingProductError from 'framework/spree/errors/MissingProductError'
import MissingVariantError from 'framework/spree/errors/MissingVariantError'
import { jsonApi } from '@spree/storefront-api-v2-sdk'
import type { WishedItemAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/WishedItem'
import type { WishlistAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Wishlist'
import type {
  ExplicitCommerceWishlist,
  ProductExtraAttr,
  SpreeSdkResponse,
  VariantAttr,
} from 'framework/spree/types/index'
import normalizeProduct from './normalize-product'

interface WishedItemExtraAttr extends WishedItemAttr {
  attributes: WishedItemAttr['attributes'] & { created_at: string }
}

export interface WishlistExtraAttr extends WishlistAttr {
  attributes: WishlistAttr['attributes'] & { share_token: string } & {
    share_url: string
  }
}

const normalizeWishlist = (
  spreeSuccessResponse: SpreeSdkResponse,
  spreeWishlist: WishlistExtraAttr,
): ExplicitCommerceWishlist => {
  const spreeWishedItems =
    jsonApi.findRelationshipDocuments<WishedItemExtraAttr>(
      spreeSuccessResponse,
      spreeWishlist,
      'wished_items',
    )

  const items: ExplicitCommerceWishlist['items'] = spreeWishedItems.map(
    spreeWishedItem => {
      const spreeWishedVariant =
        jsonApi.findSingleRelationshipDocument<VariantAttr>(
          spreeSuccessResponse,
          spreeWishedItem,
          'variant',
        )

      if (spreeWishedVariant === null) {
        throw new MissingVariantError(
          `Couldn't find variant for wished item with id ${spreeWishedItem.id}.`,
        )
      }

      const spreeWishedProduct =
        jsonApi.findSingleRelationshipDocument<ProductExtraAttr>(
          spreeSuccessResponse,
          spreeWishedVariant,
          'product',
        )

      if (spreeWishedProduct === null) {
        throw new MissingProductError(
          `Couldn't find product for variant with id ${spreeWishedVariant.id}.`,
        )
      }

      return {
        id: spreeWishedItem.id,
        product_id: parseInt(spreeWishedProduct.id, 10),
        variant_id: parseInt(spreeWishedVariant.id, 10),
        product: normalizeProduct(spreeSuccessResponse, spreeWishedProduct),
        created_at: spreeWishedItem.attributes.created_at,
      }
    },
  )

  const {
    id,
    attributes: { name, share_token: shareToken, share_url: shareUrl, token },
  } = spreeWishlist

  return {
    id,
    name,
    token,
    shareUrl,
    shareToken,
    items,
  }
}

export default normalizeWishlist
