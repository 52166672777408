'use client'

import cn from 'classnames'
import React, {
  FC,
  forwardRef,
  ButtonHTMLAttributes,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import { LoadingDots } from '@components/ui'
import s from './Button.module.css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'brand'
    | 'outlined'
    | 'black'
    | 'link'
    | 'brandLink'
    | 'clear'
    | 'inverted'
    | 'outlinedBrand'
  size?: 'big' | 'medium'
  className?: string
  active?: boolean
  Component?: string | JSXElementConstructor<any>
  loading?: boolean
  disabled?: boolean
}

// eslint-disable-next-line react/display-name
const Button: FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    variant,
    size,
    className,
    children,
    active,
    loading = false,
    disabled = false,
    Component = 'button',
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  const rootClassName = cn(s.root, {
    [s.inverted]: variant === 'inverted',
    [s.link]: variant === 'link',
    [s.brandLink]: variant === 'brandLink',
    [s.brand]: variant === 'brand',
    [s.black]: variant === 'black',
    [s.clear]: variant === 'clear',
    [s.outlined]: variant === 'outlined',
    [s.outlinedBrand]: variant === 'outlinedBrand',
    [s.big]: size === 'big',
    [s.medium]: size === 'medium',
    [s.loading]: loading,
    [s.disabled]: disabled,
  })

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={cn(className, rootClassName)}
      disabled={disabled}
      {...rest}
    >
      {children}
      {loading && (
        <i className='pl-2 m-0 flex'>
          <LoadingDots />
        </i>
      )}
    </Component>
  )
})

export default Button
