'use client'

import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { BrandTheme } from '@models'
import { isEmptyObject, setCSSProperty } from 'utils/helpers'
import { useStoreDataContext } from './storeDataContext/useStoreDataContext'
import { useHealth } from '@lib/hooks/useHealth'
import { Props } from 'react-select'

export const BrandThemeContext = createContext({} as BrandTheme)

BrandThemeContext.displayName = 'BrandThemeContext'

export const setThemeVariables = () => {
  const theme: BrandTheme = JSON.parse(
    localStorage.getItem('brandTheme') ?? '{}',
  )

  if (!isEmptyObject(theme)) {
    setCSSProperty('--brand', theme.color)
    setCSSProperty('--brand-hover', theme.brand_color_hover)
    setCSSProperty('--brand-active', theme.brand_color_active)
    setCSSProperty('--border-radius-banner', `${theme.banner_border_radius}px`)
    setCSSProperty('--groupedInputBg', theme.grouped_input_background)
    setCSSProperty(
      '--shipping-returns-bg',
      theme.shipping_and_returns_background,
    )
    setCSSProperty('--border-radius-brand', `${theme.border_radius}px`)
    setCSSProperty('--top-panel-bg', theme.top_panel_background)
    setCSSProperty('--accordionBg', theme.accordion_background)
    setCSSProperty('--accordion-text', theme.accordion_title_color)
    setCSSProperty('--accordion-active', theme.active_accordion_color)
    setCSSProperty('--accordion-expand-icon', theme.accordion_expand_icon_color)
    setCSSProperty('--secondary', theme.secondary_color)
    setCSSProperty('--headerIcon', theme.header_icons_color)
    setCSSProperty('--footerIcon', theme.footer_icon_color)
    setCSSProperty('--primary-font', theme.primary_font)
    setCSSProperty(
      '--product-slider-image-border-radius',
      `${theme.product_slider_image_border_radius}px`,
    )
    setCSSProperty(
      '--quantity-selection-button-color',
      theme.quantity_selection_button_text_color,
    )
    setCSSProperty(
      '--product-delivery-details-color',
      theme.product_delivery_details_color,
    )
    setCSSProperty(
      '--product-details-bullet-points-color',
      theme.product_details_bullet_points_color,
    )
    setCSSProperty('--secondary-font', theme.secondary_font)
    setCSSProperty('--banner-secondary-color', theme.banner_secondary_color)
    setCSSProperty('--filters-background-color', theme.filters_background_color)
    setCSSProperty(
      '--product-description-divider',
      theme.product_description_divider_color,
    )
    setCSSProperty('--message-us-scroll-color', theme.message_us_scroll_color)
    setCSSProperty(
      '--product-cart-button-color',
      theme.product_cart_button_color,
    )
    setCSSProperty('--footer-bg-color', theme.footer_bg_color)
    setCSSProperty('--footer-text-color', theme.footer_text_color)
    setCSSProperty('--footer-headings-color', theme.footer_heading_color)
    setCSSProperty(
      '--mobile-product-cart-button-color',
      theme.mobile_product_cart_button_color || theme.product_cart_button_color,
    )
    setCSSProperty(
      '--hover-product-cart-button-color',
      theme.hover_product_cart_button_color || theme.brand_color_hover,
    )
    setCSSProperty('--viewAllColor', theme.view_all_color)
    setCSSProperty('--clearFilterColor', theme.clear_filter)
    setCSSProperty('--compageAtColor', theme.compare_at_color)
  }
}

export const BrandThemeProvider = (props: PropsWithChildren<any>) => {
  const { isInitialLoading } = useHealth()

  const {
    data: {
      attributes: { brand_theme: brandTheme },
    },
  } = useStoreDataContext()

  const { children, ...otherProps } = props
  const [value, setValue] = useState<BrandTheme>({
    color: '',
    secondary_color: '',
    brand_color_hover: '',
    brand_color_active: '',
    header_icons_color: '',
    favicon_url: '',
    logo_url: '',
    menu_type: '',
    primary_font: '',
    secondary_font: '',
    icons_set: '',
    filter_section_border: 'around',
    home_page_small_banner_type: 'type 1',
    border_radius: 8,
    top_panel_background: '',
    accordion_background: '',
    accordion_title_color: '',
    active_accordion_color: '',
    accordion_expand_icon_color: '',
    shipping_and_returns_background: '#fff',
    grouped_input_background: '#fff',
    banner_border_radius: 0,
    product_slider_image_border_radius: 0,
    quantity_selection_button_text_color: '#000000',
    product_delivery_details_color: '#000000',
    product_details_bullet_points_color: 'red',
    product_card_view_button_type: 'outlined-gray',
    home_page_main_banner_type: 'centered',
    filters_background_color: '#F8F8F8',
    xs_banner_number: 1,
    small_banner_number: 1,
    footer_icon_color: '',
    banner_secondary_color: '',
    product_description_divider_color: '',
    message_us_scroll_color: '',
    filter_section_shadow: 'none',
    footer_bg_color: '',
    product_cart_button_color: '',
    footer_text_color: '',
    footer_heading_color: '',
    show_footer_logo: true,
    mobile_product_cart_button_color: '',
    hover_product_cart_button_color: '',
    banner_overlay_opacity: 0,
    clear_filter: '',
    view_all_color: '',
    compare_at_color: '',
    watermark_url: '',
  })

  useEffect(() => {
    setThemeVariables()
  }, [])

  useEffect(() => {
    if (!isEmptyObject(brandTheme)) {
      localStorage.setItem('brandTheme', JSON.stringify(brandTheme))
      setThemeVariables()
    }
    setValue(brandTheme)
  }, [brandTheme])

  return (
    <BrandThemeContext.Provider value={value} {...otherProps}>
      {isInitialLoading ? null : children}
    </BrandThemeContext.Provider>
  )
}

export const useBrandThemeContext = () => {
  const context = useContext<BrandTheme>(BrandThemeContext)

  if (context === undefined) {
    throw new Error(
      'useBrandThemeContext must be used within a BrandThemeContext',
    )
  }

  return context
}
