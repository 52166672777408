'use client'

import { StoreData } from '@models'
import { createContext } from 'react'

type Store = StoreData & { isLoading: boolean }

export const StoreDataContext = createContext({
    data: {
        attributes: {}
    }
} as Store)
