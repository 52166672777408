import type { ExpandedProductOption } from '@framework/types'

const sortOptionsByPosition = (
  options: ExpandedProductOption[],
): ExpandedProductOption[] =>
  options.sort(
    (firstOption, secondOption) => firstOption.position - secondOption.position,
  )

export default sortOptionsByPosition
