import { useHook, useMutationHook } from '@commerce/utils/use-hook'
import { mutationFetcher } from '@commerce/utils/default-fetcher'
import type { MutationHook } from '@commerce/utils/types'
import type { AddItemHook } from '@commerce/types/wishlist'
import type { Provider } from '..'

export type UseAddItem<
  H extends MutationHook<AddItemHook<any>> = MutationHook<AddItemHook>,
> = ReturnType<H['useHook']>

export const fetcher = mutationFetcher

const fn = (provider: Provider) => provider.wishlist.useAddItem!

const useAddItem: UseAddItem = (...args) => {
  const hook = useHook(fn)

  return useMutationHook({ fetcher, ...hook })(...args)
}

export default useAddItem
