import { useContext } from 'react'
import { StoreData } from '@models'
import { StoreDataContext } from './storeDataContext'

type Store = StoreData & { isLoading: boolean }

export const useStoreDataContext = () => {
  const context = useContext<Store>(StoreDataContext)

  if (context === undefined) {
    throw new Error(
      'useStoreDataContext must be used within a StoreDataProvider',
    )
  }

  return context
}
