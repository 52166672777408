export enum BANNER_ELEMENT_TYPES {
  SMALL_BANNER = 'Small Banner',
  MAIN_BANNER = 'Main Banner',
  XS_BANNER = 'XS Banner',
  FOOTER_BANNER = 'Footer Banner',
  BOTTOM_BANNER = 'Bottom Banner',
  BLOG_BANNER = 'Blog Banner',
}

export type CmsPage = {
  attributes: {
    content: {
      button_text: string
      subtitle: string
      title: string
      rte_content: string
      banner_element: BANNER_ELEMENT_TYPES
      blog_url?: string
    }
    rte_content: string
    fit: string
    img_one_lg: string | null
    img_one_md: string | null
    img_one_sm: string | null
    img_one_xl: string | null
    img_three_lg: string | null
    img_three_md: string | null
    img_three_sm: string | null
    img_three_xl: string | null
    img_two_lg: string | null
    img_two_md: string | null
    img_two_sm: string | null
    img_two_xl: string | null
    is_fullscreen: boolean
    link: string
    name: string
    title: string
    slug: string
    position: number
    settings: {
      gutters: string
    }
    type: string
  }
  id: string
  relationships: {
    linked_resource: {
      data: { id: string; type: string }
    }
  }

  type: string
}

export interface PageMeta {
  count: number
  total_count: number
  total_pages: number
}

export type CmsPages = { meta: PageMeta; included: CmsPage[] }

export type AllCmsPages = {
  data: CmsPage[]
}
