import React from 'react'

export const HeartThird = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Heart Icon</title>
    <path
      d='M16.3182 28.5657C16.1435 28.5674 15.9715 28.5233 15.8192 28.4377C15.269 28.1306 2.5 20.8761 2.5 11.6768C2.5 10.0655 3.00695 8.49517 3.94905 7.18808C4.89115 5.88099 6.22062 4.90346 7.74916 4.39395C9.2777 3.88443 10.9278 3.86878 12.4657 4.34919C14.0037 4.8296 15.3514 5.78173 16.3182 7.07071C17.2849 5.78173 18.6327 4.8296 20.1706 4.34919C21.7086 3.86878 23.3587 3.88443 24.8872 4.39395C26.4157 4.90346 27.7452 5.88099 28.6873 7.18808C29.6294 8.49517 30.1364 10.0655 30.1364 11.6768C30.1364 15.5919 27.8717 19.6094 23.4064 23.6269C21.3788 25.4498 19.171 27.0616 16.8172 28.4377C16.6649 28.5233 16.4928 28.5674 16.3182 28.5657ZM10.1768 6.04714C8.6837 6.04714 7.25178 6.64026 6.19602 7.69602C5.14026 8.75178 4.54714 10.1837 4.54714 11.6768C4.54714 18.7394 14.0152 24.9448 16.3182 26.3522C18.6212 24.9448 28.0892 18.7394 28.0892 11.6768C28.0904 10.3753 27.6407 9.11368 26.8165 8.10648C25.9923 7.09927 24.8446 6.40873 23.5687 6.15234C22.2927 5.89595 20.9674 6.08954 19.8181 6.70019C18.6688 7.31084 17.7666 8.30081 17.265 9.50168C17.1879 9.68942 17.0567 9.84999 16.8881 9.96299C16.7195 10.076 16.5211 10.1363 16.3182 10.1363C16.1152 10.1363 15.9169 10.076 15.7483 9.96299C15.5797 9.84999 15.4485 9.68942 15.3714 9.50168C14.9453 8.47589 14.2241 7.59984 13.2992 6.98477C12.3743 6.36969 11.2875 6.04334 10.1768 6.04714Z'
      fill='currentColor'
    />
  </svg>
)
