import cn from 'classnames'
import React, { FC } from 'react'

interface ContainerProps {
  className?: string
  children?: any
  el?: HTMLElement
  clean?: boolean
  main?: boolean
  common?: boolean
  account?: boolean
}

const Container: FC<ContainerProps> = ({
  children,
  className,
  el = 'div',
  clean,
  main,
  common,
  account,
}) => {
  const rootClassName = cn(className, {
    'mx-auto max-w-9xl sm:px-6 w-full': !clean && !main && !common,
    'mx-auto max-w-6xl lg:px-5 px-3 w-full': main,
    'mx-auto max-w-6xl px-5': common,
    'mx-auto max-w-[1195px] px-5': account,
  })

  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return <Component className={rootClassName}>{children}</Component>
}

export default Container
