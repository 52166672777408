import { FC, ReactElement } from 'react'
import { ICONS_SET_TYPE } from '@constants/common'
import { useBrandThemeContext } from '@contexts/brandThemeContext'

interface IconProps {
  set1: ReactElement
  set2: ReactElement
  set3: ReactElement
  setNinelife: ReactElement
  setNinefit: ReactElement
  setCopperStudio?: ReactElement
}

export const Icon: FC<IconProps> = ({
  set1,
  set2,
  set3,
  setNinelife,
  setNinefit,
  setCopperStudio,
}) => {
  const { icons_set: iconsSet } = useBrandThemeContext()

  switch (iconsSet) {
    case ICONS_SET_TYPE.SET_1:
      return set1

    case ICONS_SET_TYPE.SET_2:
      return set2

    case ICONS_SET_TYPE.SET_3:
      return set3

    case ICONS_SET_TYPE.SET_NINELIFE:
      return setNinelife || set1
    case ICONS_SET_TYPE.SET_NINEFIT:
      return setNinefit
    case ICONS_SET_TYPE.SET_COPPERSTUDIO:
      return setCopperStudio || set1
    default:
      return set1
  }
}
