import { useMemo } from 'react'

import type { SWRHook } from '@commerce/utils/types'
import useCart, { UseCart } from '@commerce/cart/use-cart'
import type { GetCartHook } from '@commerce/types/cart'
import type { GraphQLFetcherResult } from '@commerce/api'
import { IOrderExtraAttr } from '@framework/types'
import type { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { FetcherError } from '@commerce/utils/errors'

import { setCartToken } from '@framework/utils/tokens/cart-token'
import normalizeCart from '@framework/utils/normalizations/normalize-cart'
import ensureIToken from '@framework/utils/tokens/ensure-itoken'
import isLoggedIn from '@framework/utils/tokens/is-logged-in'
import createEmptyCart from '@framework/utils/create-empty-cart'
import {
  getCartUpdateDate,
  setCartUpdateDate,
} from '@framework/utils/cookies/cart-update-date'
import Cookies from 'js-cookie'

const imagesSize = process.env.NEXT_PUBLIC_SPREE_IMAGES_SIZE as string
const imagesQuality = process.env.NEXT_PUBLIC_SPREE_IMAGES_QUALITY as unknown as number

export default useCart as UseCart<typeof handler>

// This handler avoids calling /api/cart.
// There doesn't seem to be a good reason to call it.
// So far, only @framework/bigcommerce uses it.
export const handler: SWRHook<GetCartHook> = {
  // Provide fetchOptions for SWR cache key
  fetchOptions: {
    url: 'cart',
    query: 'show',
  },
  async fetcher({ input, options, fetch }) {
    console.info(
      'useCart fetcher called. Configuration: ',
      'input: ',
      input,
      'options: ',
      options,
    )

    let spreeCartResponse: IOrderExtraAttr | null

    const token: IToken | undefined = ensureIToken()
    const updatedAtCheck = getCartUpdateDate()

    if (!token) {
      spreeCartResponse = null
    } else {
      try {
        const { data: spreeCartShowSuccessResponse } = await fetch<
          GraphQLFetcherResult<IOrderExtraAttr>
        >({
          variables: {
            methodPath: 'cart.show',
            arguments: [
              token,
              {
                include: [
                  'line_items',
                  'line_items.variant',
                  'line_items.variant.product',
                  'line_items.variant.product.taxons',
                  'line_items.variant.product.taxons.parent.parent.parent',
                  'line_items.variant.product.images',
                  'line_items.variant.images',
                  'line_items.variant.option_values',
                  'line_items.variant.product.option_types',
                  'line_items.variant.product.product_properties',
                  'shipping_address',
                  'billing_address',
                  'payments',
                  'payments.source',
                  'shipments',
                  'shipments.shipping_rates',
                ].join(','),
                image_transformation: {
                  quality: imagesQuality,
                  size: imagesSize,
                },
                updated_at_check: updatedAtCheck,
              },
            ],
          },
        })

        spreeCartResponse = spreeCartShowSuccessResponse
      } catch (fetchCartError) {
        if (
          !(fetchCartError instanceof FetcherError) ||
          fetchCartError.status !== 404
        ) {
          throw fetchCartError
        }

        spreeCartResponse = null
      }
    }

    if (!spreeCartResponse || spreeCartResponse?.data.attributes.completed_at) {
      const { data: spreeCartCreateSuccessResponse } = await createEmptyCart(
        fetch,
      )

      spreeCartResponse = spreeCartCreateSuccessResponse
      if (!isLoggedIn()) {
        setCartToken(spreeCartResponse.data.attributes.token)
      }
    }

    setCartToken(spreeCartResponse.data.attributes.token)
    setCartUpdateDate(spreeCartResponse.data.attributes.updated_at.toString())

    return normalizeCart(spreeCartResponse, spreeCartResponse.data)
  },
  useHook: ({ useData }) => {
    const useWrappedHook: ReturnType<
      SWRHook<GetCartHook>['useHook']
    > = input => {
      const response = useData({
        swrOptions: { revalidateOnFocus: false, ...input?.swrOptions },
        input: {
          userCookie: Cookies.get(
            process.env.NEXT_PUBLIC_SPREE_USER_COOKIE_NAME as string,
          ),
        },
      })

      return useMemo<typeof response & { isEmpty: boolean }>(
        () =>
          Object.create(response, {
            isEmpty: {
              get() {
                return (response.data?.lineItems.length ?? 0) === 0
              },
              enumerable: true,
            },
          }),
        [response],
      )
    }

    return useWrappedHook
  },
}
