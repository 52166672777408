import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { ThemeProvider } from 'next-themes'

type Props = Record<string, unknown>

export interface State {
  displaySidebar: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string | null
  props: Props
}

const initialState = {
  displaySidebar: false,
  displayModal: false,
  modalView: null,
  sidebarView: 'MOBILEMENU_VIEW',
  userAvatar: '',
  props: { maxWidth: 'small' },
}

type MODAL_TYPES =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'REQUEST_VIEW'
  | 'MESSAGE_VIEW'
  | 'EMAIL_WISHLIST'
  | 'SHARE_WISHLIST'

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_TYPES
      props: Props
    }

export const UIContext = createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        modalView: null,
        displayModal: false,
        props: { maxWidth: 'small' },
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
        props: action.props || {},
      }
    }
    default:
      return state
  }
}

export const UIProvider = (props: any) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch],
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch],
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar],
  )

  const openModal = useCallback(() => {
    dispatch({ type: 'OPEN_MODAL' })
  }, [dispatch])
  const closeModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' })
  }, [dispatch])

  const setModalView = useCallback(
    (view: MODAL_TYPES, modalProps: Props) =>
      dispatch({ type: 'SET_MODAL_VIEW', view, props: modalProps }),
    [dispatch],
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      openModal,
      closeModal,
      setModalView,
    }),
    [
      closeModal,
      closeSidebar,
      openModal,
      openSidebar,
      setModalView,
      state,
      toggleSidebar,
    ],
  )

  console.log('rr')

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = useContext(UIContext)

  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider')
  }

  return context
}

export const ManagedUIContext = ({ children }: PropsWithChildren) => (
  <UIProvider>
    <ThemeProvider defaultTheme='light'>{children}</ThemeProvider>
  </UIProvider>
)
