import { SpreeProductImage } from '@framework/types'
import getImageUrl from './get-image-url'

const createGetAbsoluteImageUrl =
  (host: string, useOriginalImageSize = true) =>
  (image: SpreeProductImage, minWidth: number): string | null => {
    let url

    if (useOriginalImageSize) {
      url =
        image.attributes.original_url ||
        image.attributes.transformed_url ||
        null
    } else {
      url = getImageUrl(image, minWidth)
    }

    if (url === null) {
      return null
    }

    return `${url}`
  }

export default createGetAbsoluteImageUrl
