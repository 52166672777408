import type { SWRHook } from '@commerce/utils/types'
import useCustomer, { UseCustomer } from '@commerce/customer/use-customer'
import type { CustomerHook } from '@commerce/types/customer'
import type { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import type { GraphQLFetcherResult } from '@commerce/api'
import Cookies from 'js-cookie'
import type { IAccount } from '@spree/storefront-api-v2-sdk/types/interfaces/Account'
import { FetcherError } from '@commerce/utils/errors'
import normalizeUser from '@framework/utils/normalizations/normalize-user'
import isLoggedIn from '@framework/utils/tokens/is-logged-in'
import ensureIToken from '@framework/utils/tokens/ensure-itoken'
import { createAddress } from '@api/CreateAddress'
import { updateAccount } from '@api/UpdateAccount'
import { SAVED_ADDRESSES_LOCAL_KEY } from '@constants/common'
import { Address } from '@models/address'

export default useCustomer as UseCustomer<typeof handler>

export const handler: SWRHook<CustomerHook> = {
  // Provide fetchOptions for SWR cache key
  fetchOptions: {
    url: 'account',
    query: 'get',
  },
  async fetcher({ input, options, fetch }) {
    console.info(
      'useCustomer fetcher called. Configuration: ',
      'input: ',
      input,
      'options: ',
      options,
    )

    if (!isLoggedIn()) {
      return null
    }

    const token: IToken | undefined = ensureIToken()

    if (!token) {
      return null
    }

    try {
      const { data: spreeAccountInfoSuccessResponse } = await fetch<
        GraphQLFetcherResult<IAccount>
      >({
        variables: {
          methodPath: 'account.accountInfo',
          arguments: [token],
        },
      })

      const spreeUser = spreeAccountInfoSuccessResponse.data

      const normalizedUser = normalizeUser(
        spreeAccountInfoSuccessResponse,
        spreeUser,
      )
      const addressesInLocalStorage = JSON.parse(
        localStorage.getItem(SAVED_ADDRESSES_LOCAL_KEY) ||
          '{ "addresses": [] }',
      ) as { addresses: Address[] }
      if (addressesInLocalStorage.addresses.length > 0) {
        const addressesPromises = addressesInLocalStorage.addresses.map(el => {
          return createAddress({
            address: el,
            countryISO: el.country_iso || '',
          })
        })
        localStorage.removeItem(SAVED_ADDRESSES_LOCAL_KEY)
        if (normalizedUser && !normalizedUser.default_shipping_address.data) {
          Promise.all(addressesPromises).then(resp => {
            updateAccount({
              ship_address_id: resp[0].data.data.id,
              bill_address_id: resp[0].data.data.id,
            })
          })
        }
      }
      return normalizedUser
    } catch (fetchUserError) {
      if (
        !(fetchUserError instanceof FetcherError) ||
        fetchUserError.status !== 404
      ) {
        throw fetchUserError
      }

      return null
    }
  },
  useHook: ({ useData }) => {
    const useWrappedHook: ReturnType<
      SWRHook<CustomerHook>['useHook']
    > = input =>
      useData({
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
        input: {
          userCookie: Cookies.get(
            process.env.NEXT_PUBLIC_SPREE_USER_COOKIE_NAME as string,
          ),
        },
      })

    return useWrappedHook
  },
}
