import { useHook, useMutationHook } from '@commerce/utils/use-hook'
import { mutationFetcher } from '@commerce/utils/default-fetcher'
import type { HookFetcherFn, MutationHook } from '@commerce/utils/types'
import type { RemoveItemHook } from '@commerce/types/wishlist'
import type { Provider } from '..'

export type UseRemoveItem<
  H extends MutationHook<RemoveItemHook<any>> = MutationHook<RemoveItemHook>,
> = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<RemoveItemHook> = mutationFetcher

const fn = (provider: Provider) => provider.wishlist.useRemoveItem!

const useRemoveItem: UseRemoveItem = (...args) => {
  const hook = useHook(fn)

  return useMutationHook({ fetcher, ...hook })(...args)
}

export default useRemoveItem
