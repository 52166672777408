import type { Customer } from '@commerce/types/customer'
import type { AccountAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Account'
import type { SpreeSdkResponse } from '@framework/types'

const normalizeUser = (
  _spreeSuccessResponse: SpreeSdkResponse,
  spreeUser: AccountAttr,
  ): Customer => {
  const { email } = spreeUser.attributes
  const { default_billing_address } = spreeUser.relationships
  const { default_shipping_address } = spreeUser.relationships
  const { first_name } = spreeUser.attributes
  const { last_name } = spreeUser.attributes

  return {
    email,
    default_billing_address,
    default_shipping_address,
    first_name,
    last_name,
  }
}

export default normalizeUser
