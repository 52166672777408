import axios from 'axios'
import { getUserTokenResponse } from '@framework/utils/tokens/user-token-response'
import { Address } from 'models/address'

interface ICreateAddress {
  address: Address,
  countryISO: string,
}

export const createAddress = async ({
  address,
  countryISO,
}: ICreateAddress) => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST
  const token = getUserTokenResponse()
  const config = {
    headers: {
      ...(token
        ? { Authorization: `${token?.token_type} ${token?.access_token}` }
        : {}),
    },
  }

  return axios.post(
    `${apiUrl}/api/v2/storefront/account/addresses`,
    {
      address: {
        ...address,
        country_iso: countryISO,
      },
    },
    config,
  )
}
