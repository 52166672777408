export const HeartSecond = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Heart Icon</title>
    <path
      d='M16.1337 24.7333L16.0003 24.8667L15.8537 24.7333C9.52033 18.9867 5.33366 15.1867 5.33366
        11.3333C5.33366 8.66667 7.33366 6.66667 10.0003 6.66667C12.0537 6.66667 14.0537 8 14.7603 9.81333H17.2403C17.947
        8 19.947 6.66667 22.0003 6.66667C24.667 6.66667 26.667 8.66667 26.667 11.3333C26.667 15.1867 22.4803 18.9867 16.1337
        24.7333ZM22.0003 4C19.6803 4 17.4537 5.08 16.0003 6.77333C14.547 5.08 12.3203 4 10.0003 4C5.89366 4 2.66699 7.21333
        2.66699 11.3333C2.66699 16.36 7.20033 20.48 14.067 26.7067L16.0003 28.4667L17.9337 26.7067C24.8003 20.48 29.3337
        16.36 29.3337 11.3333C29.3337 7.21333 26.107 4 22.0003 4Z'
      fill='currentColor'
    />
  </svg>
)
