import axios from 'axios'
import { getUserTokenResponse } from '@framework/utils/tokens/user-token-response'

interface IUpdateAccount {
  firstname?: string,
  lastname?: string,
  ship_address_id?: string,
  bill_address_id?: string,
}

export const updateAccount = ({
  ship_address_id,
  firstname,
  lastname,
  bill_address_id,
}: IUpdateAccount) => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST
  const token = getUserTokenResponse()
  const config = {
    headers: {
      Authorization: `${token?.token_type} ${token?.access_token}`,
      Token: token?.access_token || '',
    },
  }

  return axios.patch(
    `${apiUrl}/api/v2/storefront/account`,
    {
      user: {
        first_name: firstname,
        last_name: lastname,
        ...(bill_address_id || bill_address_id === null ? { bill_address_id } : {}),
        ...(ship_address_id || ship_address_id === null ? { ship_address_id } : {}),
      },
    },
    config,
  )
}
