import React from 'react'

export const HeartIconNinelife = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Heart Icon</title>
    <path d='M16.0002 8.39686L14.9247 7.21767C12.4001 4.44968 7.77096 5.40487 6.09991 8.88486C5.31538 10.5217 5.13838 12.8848 6.57092 15.9008C7.95096 18.8048 10.8221 22.2832 16.0002 26.072C21.1784 22.2832 24.048 18.8048 25.4295 15.9008C26.862 12.8832 26.6865 10.5217 25.9005 8.88486C24.2295 5.40487 19.6003 4.44808 17.0757 7.21607L16.0002 8.39686ZM16.0002 28C-6.99999 11.7889 8.91849 -0.863907 15.7362 5.82887C15.8262 5.91687 15.9147 6.00807 16.0002 6.10247C16.0848 6.00816 16.1729 5.91742 16.2642 5.83047C23.0804 -0.867106 39.0004 11.7873 16.0002 28Z' />
  </svg>
)
