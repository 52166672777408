import { ErrorProps } from '.'
import { CommerceError } from './CommerceError'

export class FetcherError extends CommerceError {
  status: number

  constructor(
    options: {
      status: number,
    } & ErrorProps,
  ) {
    super(options)
    this.status = options.status
  }
}
