import Cookies from 'js-cookie'

export const getCartToken = () =>
  Cookies.get(process.env.NEXT_PUBLIC_SPREE_CART_COOKIE_NAME as string) || false

export const setCartToken = (cartToken: string) => {
  const cookieOptions = {
    expires: 7,
  }

  Cookies.set(
    process.env.NEXT_PUBLIC_SPREE_CART_COOKIE_NAME as string,
    cartToken,
    cookieOptions,
  )
}

export const removeCartToken = () => {
  Cookies.remove(process.env.NEXT_PUBLIC_SPREE_CART_COOKIE_NAME as string)
}
