import { ErrorData, ErrorProps } from '.'

export class CommerceError extends Error {
  code?: string

  errors: ErrorData[]

  constructor({ message, code, errors }: ErrorProps) {
    const error: ErrorData = message
      ? { message, ...(code ? { code } : {}) }
      : errors![0]

    super(error.message)
    this.errors = message ? [error] : errors!

    if (error.code) this.code = error.code
  }
}
